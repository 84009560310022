"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPermissions = exports.PermissionsReducerSlice = void 0;
//@ts-nocheck
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    userPermissions: [],
};
exports.PermissionsReducerSlice = (0, toolkit_1.createSlice)({
    name: 'Permissions',
    initialState,
    reducers: {
        setPermissions: (state, action) => {
            state.userPermissions = action.payload;
        },
    },
});
// Action creators are generated for each case reducer function
exports.setPermissions = exports.PermissionsReducerSlice.actions.setPermissions;
exports.default = exports.PermissionsReducerSlice.reducer;
