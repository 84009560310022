"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setZoom = exports.setShow = exports.setSearchLong = exports.setSearchLat = exports.SearchMarkerSlice = void 0;
//@ts-nocheck
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    lat: 0,
    long: 0,
    show: false,
    zoom: undefined,
};
exports.SearchMarkerSlice = (0, toolkit_1.createSlice)({
    name: 'SearchMarker',
    initialState,
    reducers: {
        setSearchLat: (state, action) => {
            state.lat = action.payload;
        },
        setSearchLong: (state, action) => {
            state.long = action.payload;
        },
        setShow: (state, action) => {
            state.show = action.payload;
        },
        setZoom: (state, action) => {
            state.zoom = action.payload;
        },
    },
});
// Action creators are generated for each case reducer function
_a = exports.SearchMarkerSlice.actions, exports.setSearchLat = _a.setSearchLat, exports.setSearchLong = _a.setSearchLong, exports.setShow = _a.setShow, exports.setZoom = _a.setZoom;
exports.default = exports.SearchMarkerSlice.reducer;
