"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const styles_1 = require("@mui/styles");
const Constants_1 = require("../../../utils/Constants");
exports.useStyles = (0, styles_1.makeStyles)(() => ({
    accordianWrapper: {
        "& .MuiDivider-root": {
            borderColor: Constants_1.Colors.BALI_HAI,
        },
        "& .Mui-expanded": {
            backgroundColor: "#EEF3F7 !important",
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6
        },
        "& .MuiAccordionSummary-root": {
            minHeight: "unset !important",
            height: 48
        },
        "& .MuiAccordionDetails-root": {
            padding: "16px 16px 3px 16px"
        },
        "& .textFieldDisabled": {
            backgroundColor: Constants_1.Colors.LIGHTERGREY
        }
    },
    textFieldWrapper: {
        color: Constants_1.Colors.SILVER_CHALICE,
        "& .MuiFormControl-root": {
            backgroundColor: "transparent !important"
        },
        "& .MuiInputBase-root": {
            height: "25px !important",
            "& .cameraIcon": {
                height: 24,
                cursor: "pointer",
                "& img": {
                    height: 24,
                    width: 24,
                    position: "absolute",
                    right: 0,
                    top: -10
                }
            }
        },
        "& .MuiInputBase-input": {
            paddingLeft: "unset !important",
            color: Constants_1.Colors.BLACK
        },
        "& .MuiFormHelperText-root": {
            marginBottom: "unset !important",
            marginTop: 0,
            paddingTop: 8
        }
    },
    tabBtns: {
        "& .MuiButtonBase-root": {
            color: Constants_1.Colors.GREY_HEADER,
            borderRadius: "unset !important",
            backgroundColor: "unset !important",
            border: "unset !important",
            borderBottom: `3px solid ${Constants_1.Colors.GREY_HEADER} !important`,
            borderRight: `3px dashed ${Constants_1.Colors.GREY_HEADER} !important`,
            "&:last-child": {
                borderRight: `unset !important`,
            },
            "&:hover": {
                backgroundColor: "unset !important",
            }
        },
        "& .Mui-selected": {
            color: Constants_1.Colors.BLUE,
            borderBottom: `3px solid ${Constants_1.Colors.BLUE} !important`,
        }
    },
    selectFieldWrapper: {
        "& .MuiFormControl-root": {
            height: "25px !important",
            marginBottom: 8
        },
        "& .MuiInputBase-root": {
            height: "25px !important",
            color: Constants_1.Colors.BLACK,
        },
        "& .MuiSelect-select": {
            paddingLeft: "unset !important"
        },
        "& .MuiFormHelperText-root": {
            marginBottom: "unset !important",
            marginTop: 8
        }
    },
    cameraIcon: {},
    clearIcon: {
        position: 'absolute',
        top: 5,
        right: 5,
        cursor: "pointer"
    }
}));
