"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const styles_1 = require("@mui/styles");
const Constants_1 = require("../../../utils/Constants");
exports.useStyles = (0, styles_1.makeStyles)({
    customCheckbox: {
        color: Constants_1.Colors.CHECKBOX,
        // border: `1px solid ${Colors.CHECKED}`,
        '&.Mui-checked': {
            color: Constants_1.Colors.CHECKED_TICK,
        },
        '& .MuiSvgIcon-root': {},
        "&:hover": {
            backgroundColor: "unset"
        },
    },
});
