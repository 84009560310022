"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authActions = void 0;
// @ts-nocheck
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    access_token: undefined,
    refresh_token: undefined,
    email: undefined,
    status: undefined,
};
const authSlice = (0, toolkit_1.createSlice)({
    name: 'authentication',
    initialState,
    reducers: {
        login(state, action) {
            state.access_token = action.payload.access_token;
            state.refresh_token = action.payload.refresh_token;
            state.email = action.payload.email;
        },
        status(state, action) {
            state.status = action.payload.status;
        },
    },
});
exports.authActions = authSlice.actions;
exports.default = authSlice.reducer;
