"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTilt = exports.AzimuthOfTwoPoins = void 0;
const AzimuthOfTwoPoins = (_start, _end) => {
    let p1 = _start;
    let p2 = _end;
    p1[1] = p1[1];
    p1[0] = p1[0];
    p2[1] = p2[1];
    p2[0] = p2[0];
    let x = Math.cos(radians(p2[1])) * Math.sin(radians(p2[0] - p1[0]));
    let y = Math.cos(radians(p1[1])) * Math.sin(radians(p2[1])) -
        Math.sin(radians(p1[1])) *
            Math.cos(radians(p2[1])) *
            Math.cos(radians(p2[0] - p1[0]));
    let final = Math.atan2(x, y);
    let azimuth = final * (180 / Math.PI);
    if (azimuth < 0) {
        azimuth = azimuth + 360;
    }
    return azimuth;
};
exports.AzimuthOfTwoPoins = AzimuthOfTwoPoins;
const radians = (degrees) => {
    return (degrees * Math.PI) / 180;
};
const calculateTilt = (radiation1, radiation2, distance) => {
    let tiltValue = radians(Math.atan((radiation1 - radiation2) / distance));
    return tiltValue;
};
exports.calculateTilt = calculateTilt;
