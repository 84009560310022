"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.styleActions = void 0;
// @ts-nocheck
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    platform: undefined,
};
const styleSlice = (0, toolkit_1.createSlice)({
    name: 'style',
    initialState,
    reducers: {
        style(state, action) {
            state.platform = action.payload.platform;
        },
    },
});
exports.styleActions = styleSlice.actions;
exports.default = styleSlice.reducer;
