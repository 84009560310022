"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serviceActions = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    SAS: ['Please choose type'],
    AFC: ['Please choose type'],
    mapping_dict: {},
};
const serviceProviderSlice = (0, toolkit_1.createSlice)({
    name: 'serviceProvider',
    initialState,
    reducers: {
        serviceProvider(state, action) {
            state.SAS = action.payload.SAS;
            state.AFC = action.payload.AFC;
            state.mapping_dict = action.payload.mapping_dict;
        },
    },
});
exports.serviceActions = serviceProviderSlice.actions;
exports.default = serviceProviderSlice.reducer;
