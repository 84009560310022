"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Colors = {
    RED: '#FCEAEA',
    DISABLED_GREY: '#D3D3D3',
    RED_VIVID: '#ff0000',
    LIGHT_RED: '#e3472b',
    LIGHT_ORANGE: '#f59243',
    LIGHT_PINK: '#ffdfd5',
    LILAC_BUSH: '#966FD6',
    PASTEL_GREEN: '#90EE90',
    Zeus: '#292319',
    SKY_BLUE: '#76D7EA',
    MINE_SHAFT: '#272727',
    REAL_RED: '#f33d3d',
    RED_2: '#EC2F2F',
    WHITE: 'white',
    BLUE: '#0059B2',
    GREY: '#F7F7F8',
    LIGHTGREY: '#9B9B9B',
    LIGHTERGREY: '#f6f6f6',
    GREY_HEADER: '#8F9FAC',
    MAKO: '#475057',
    ATHENS_GRAY: '#F4F5F7',
    SNOWY_MINT: '#DEFDDE',
    BLACK: 'black',
    TUNDORA: '#454545',
    GREY_PLACEHOLDER: '#939DAA',
    DISABLED_BUTTON_BACKGROUND: '#F5F5F7',
    BLUE_BACKGROUND: '#E5EEF7',
    TRANSPARENT: 'transparent',
    BRIGHT_GREY: '#5B5E62',
    MEDIUM_GREY: '#ededf0',
    GREISH_WHITE: '#EDEDF0',
    WHITE_GREY: '#F5F5F5',
    BACKGROUNDCOLOR: '#F2F2F2',
    ENABLEDGREY: '#D5D5D5',
    OUTLINECOLOR: '#CCCCCC',
    YELLOW: '#FF9445',
    RED_COLOR: 'red',
    GREY_MEDIUM_LIGHT: '#C1C1C1',
    TIMBER_WOLF: '#D9D6CF',
    LIGHT_GREY: '#F9F9FC',
    ORANGE: '#FF9445',
    DARK_ORANGE: '#FF9445',
    Orange: '#FF9445',
    BG: '#F6F7FB',
    BORDOR_COLOR: '#D5D5D5',
    BORDOR_COLOE: '#D5D5D5',
    LIGHT_GREEN: '#D9EFE4',
    GREEN_TEXT: '#008947',
    GREEN: 'green',
    LOBLOLLY: '#B7C1C9',
    PROGRESS_GREEN: '#00D56E',
    DIM_GRAY: '#FAFAFA',
    DARK_GRAY: '#42424266',
    ALTO: '#D8D8D8',
    DODGER_BLUE: '#2F80FF',
    DODGER_BLUE_LIGHT: '#3CCBFF',
    REGENT_ST_BLUE: '#ADD8E6',
    BITTERSWEET: '#FF6F69',
    GOLDEN_TAINOI: '#FFCC5C',
    VISTA_BLUE: '#88D8B0',
    SWAMP_GREEN: '#B4BE89',
    GUMBO: '#749DA1',
    DUSTY_GRAY: '#9B9B9B',
    ENDEAVOUR: '#0059B2',
    BALI_HAI: '#8F9FAC',
    AZURE_RADIANCE: '#4574f5',
    SILVER_CHALICE: '#AAAAAA',
    SILVER: '#CCCCCC',
    LINK_WATER: '#E5EEF7',
    THUNDER: '#353435',
    CARIBBEAN_GREEN: '#60d279',
    CATSKILL_WHITE: '#EEF3F7',
    NEON_CARROT: '#f09956',
    ALIZARIN_CRIMSON: '#fae04b',
    SCORPION: '#5F5F5F',
    DELTA: '#A1A39B',
    RMA_REQUESTED: '#af3ff5',
    ROYAL_ORGANGE: '#ff9445',
    MAGENTA_GREEN: '#00d56e',
    PURPLE: '#be34fe',
    RMA_in_progress: '#ffde01',
    DRAFT: '#5f5f5f',
    DEFAULT_BORDER: '#c0c0c0',
    CHECKBOX: '#CCCCCC',
    CHECKED: '#D9EFE4',
    CHECKED_TICK: '#00D56E',
};
exports.default = Colors;
