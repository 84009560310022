"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAssetDelete = exports.setSasStatus = exports.setElevationProfile = exports.setMeasureDistance = exports.setMeasureValues = exports.setSectorSheet = exports.setDocumentId = exports.getDoucmentId = exports.deleteAsset = exports.clearAssetImages = exports.toolTipOpen = exports.closeToolTip = exports.addAssetImageFromPlusIcon = exports.addAssetForImagePicker = exports.addAssetForSubmission = exports.addAssetSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    assetBody: {},
    sasStatus: false,
    imageItems: [
        {
            id: 0,
            iconName: 'home-map-marker',
            iconType: 'MaterialCommunityIcons',
            toolTip: false,
            document_id: '',
            uri: '',
            ImageName: '',
        },
        {
            id: 1,
            iconName: 'barcode-scan',
            iconType: 'MaterialCommunityIcons',
            toolTip: false,
            document_id: '',
            uri: '',
            ImageName: '',
        },
        {
            id: 2,
            iconName: 'dns-outline',
            iconType: 'MaterialCommunityIcons',
            toolTip: false,
            document_id: '',
            uri: '',
            ImageName: '',
        },
        {
            id: 3,
            iconName: 'cell-wifi',
            iconType: 'MaterialIcons',
            toolTip: false,
            document_id: '',
            uri: '',
            ImageName: '',
        },
    ],
    document_id: [],
    sectorSheet: false,
    maesureStartPoint: null,
    measureEndPoint: null,
    measureDistance: null,
    firstAssetHeight: null,
    secondAssetHeight: null,
    elevationProfile: [],
    assetDelete: false
};
exports.addAssetSlice = (0, toolkit_1.createSlice)({
    name: 'addAsset',
    initialState,
    reducers: {
        addAssetForSubmission: (state, action) => {
            state.assetBody = action.payload;
        },
        addAssetForImagePicker: (state, action) => {
            state.imageItems[action.payload.index].uri = action.payload.uri;
            state.imageItems[action.payload.index].document_id = action.payload.document_id;
            state.imageItems[action.payload.index].ImageName =
                action.payload.ImageName;
        },
        addAssetImageFromPlusIcon: (state, action) => {
            state.imageItems.push(action.payload);
        },
        closeToolTip: (state, action) => {
            state.imageItems.map((i) => {
                return (i.toolTip = false);
            });
        },
        toolTipOpen: (state, action) => {
            state.imageItems[action.payload].toolTip =
                !state.imageItems[action.payload].toolTip;
        },
        clearAssetImages: (state, action) => {
            state.imageItems.map((i, index) => {
                return ((i.toolTip = false),
                    (i.uri = ''),
                    (i.imageName = ''),
                    (i.document_id = ''));
            });
            for (let i = 0; i <= state.imageItems.length; i++) {
                if (state.imageItems.length > 4) {
                    state.imageItems.pop();
                }
            }
        },
        deleteAsset: (state, action) => {
            if (action.payload >= 4) {
                let temp = state.imageItems.filter((item, i) => {
                    return i != action.payload;
                });
                state.imageItems = temp;
            }
            else {
                state.imageItems[action.payload].uri = '';
                state.imageItems[action.payload].imageName = '';
                state.imageItems[action.payload].document_id = '';
            }
        },
        setDocumentId: (state, action) => {
            state.imageItems[action.payload.index].document_id =
                action.payload.document_id;
        },
        getDoucmentId: (state) => {
            let temp = [];
            state.imageItems.map((item) => {
                return temp.push(item.document_id);
            });
            state.document_id = temp.filter(Number);
        },
        setSectorSheet: (state, action) => {
            state.sectorSheet = action.payload;
        },
        setMeasureValues: (state, action) => {
            if (action.payload.type == 1) {
                state.maesureStartPoint = action.payload.coords;
                state.firstAssetHeight = action.payload.height;
            }
            else {
                state.measureEndPoint = action.payload.coords;
                state.secondAssetHeight = action.payload.height;
            }
        },
        setMeasureDistance: (state, action) => {
            state.measureDistance = action.payload.distance;
        },
        setElevationProfile: (state, action) => {
            state.elevationProfile = action.payload.data;
        },
        setSasStatus: (state, action) => {
            state.sasStatus = action.payload;
        },
        setAssetDelete: (state, action) => {
            state.assetDelete = action.payload;
        },
    },
});
_a = exports.addAssetSlice.actions, exports.addAssetForSubmission = _a.addAssetForSubmission, exports.addAssetForImagePicker = _a.addAssetForImagePicker, exports.addAssetImageFromPlusIcon = _a.addAssetImageFromPlusIcon, exports.closeToolTip = _a.closeToolTip, exports.toolTipOpen = _a.toolTipOpen, exports.clearAssetImages = _a.clearAssetImages, exports.deleteAsset = _a.deleteAsset, exports.getDoucmentId = _a.getDoucmentId, exports.setDocumentId = _a.setDocumentId, exports.setSectorSheet = _a.setSectorSheet, exports.setMeasureValues = _a.setMeasureValues, exports.setMeasureDistance = _a.setMeasureDistance, exports.setElevationProfile = _a.setElevationProfile, exports.setSasStatus = _a.setSasStatus, exports.setAssetDelete = _a.setAssetDelete;
exports.default = exports.addAssetSlice.reducer;
