"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formFields = exports.noOfSectorsOptions = exports.noOfTransmittersOptions = exports.vendorTypeOptions = exports.radioTechnologiesOptions = exports.deviceCategoryOptions = exports.measurementCapabilitiesOptions = void 0;
exports.measurementCapabilitiesOptions = [
    {
        value: 'RECEIVED_POWER_WITH_GRANT',
        label: 'RECEIVED_POWER_WITH_GRANT',
    },
    {
        value: 'RECEIVED_POWER_WITHOUT_GRANT',
        label: 'RECEIVED_POWER_WITHOUT_GRANT',
    },
];
exports.deviceCategoryOptions = [
    { value: 'A', label: 'A' },
    { value: 'B', label: 'B' },
];
exports.radioTechnologiesOptions = [
    { value: 'E_UTRA', label: 'E_UTRA' },
    { value: 'CAMBIUM_NETWORKS', label: 'CAMBIUM_NETWORKS' },
    { value: 'FOUR_G_BBW_SAA_1', label: 'FOUR_G_BBW_SAA_1' },
    { value: 'NR', label: 'NR' },
    { value: 'DOODLE_CBRS', label: 'DOODLE_CBRS' },
    { value: 'CW', label: 'CW' },
    { value: 'REDLINE', label: 'REDLINE' },
    { value: 'TARANA_WIRELESS', label: 'TARANA_WIRELESS' },
];
exports.vendorTypeOptions = [
    { value: 'omni', label: 'Omni' },
    { value: 'directional', label: 'Directional' },
];
exports.noOfTransmittersOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
];
exports.noOfSectorsOptions = [
    { value: 0, label: '0' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
];
exports.formFields = [
    {
        name: 'vendor_name',
        label: 'Vendor Name *',
        type: 'text',
        showHelperText: false,
    },
    {
        name: 'model_name',
        label: 'Model Name *',
        type: 'text',
        showHelperText: false,
    },
    {
        name: 'antenna_pattern',
        label: 'Antenna Pattern',
        type: 'text',
    },
    {
        name: 'fccId',
        label: 'FCC ID *',
        type: 'text',
        showHelperText: false,
    },
    {
        name: 'antennaBeamwidth',
        label: 'Antenna Beamwidth *',
        type: 'text',
        showHelperText: true,
    },
    {
        name: 'antennaModel',
        label: 'Antenna Model *',
        type: 'text',
        showHelperText: false,
    },
    {
        name: 'softwareVersion',
        label: 'Software Version *',
        type: 'text',
        showHelperText: false,
    },
    {
        name: 'hardwareVersion',
        label: 'Hardware Version *',
        type: 'text',
        showHelperText: false,
    },
    {
        name: 'firmwareVersion',
        label: 'Firmware Version *',
        type: 'text',
        showHelperText: false,
    },
    {
        name: 'antennaGain',
        label: 'Antenna Gain (dBi) *',
        type: 'text',
        showHelperText: true,
    },
    {
        name: 'eirpCapability',
        label: 'EIRP Capability (dBm) *',
        type: 'text',
        showHelperText: true,
    },
    {
        name: 'category',
        label: 'Category *',
        type: 'dropdown',
        options: exports.deviceCategoryOptions,
        showHelperText: false,
    },
    {
        name: 'technology_name',
        label: 'Technology Name *',
        type: 'dropdown',
        options: [],
        showHelperText: false,
    },
    {
        name: 'radioTechnology',
        label: 'Radio Technology *',
        type: 'dropdown',
        options: exports.radioTechnologiesOptions,
        showHelperText: false,
    },
    {
        name: 'measurementCapabilities',
        label: 'Measurement Capabilities *',
        type: 'dropdown',
        options: exports.measurementCapabilitiesOptions,
        showHelperText: false,
    },
    {
        name: 'no_of_transmitters',
        label: 'No. of Transmitters *',
        type: 'dropdown',
        options: exports.noOfTransmittersOptions,
        showHelperText: false,
    },
    {
        name: 'sector_default_value',
        label: 'No. of Sectors *',
        type: 'dropdown',
        options: exports.noOfSectorsOptions,
        showHelperText: true,
    },
    {
        name: 'deployment_type',
        label: 'Deployment Type *',
        type: 'dropdown',
        options: [],
        showHelperText: false,
    },
    {
        name: 'vendor_type',
        label: 'Directionality *',
        type: 'dropdown',
        options: exports.vendorTypeOptions,
        showHelperText: false,
    },
];
