"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable react-native/no-inline-styles */
const react_1 = __importStar(require("react"));
const SpectraGridContainer_1 = require("../../../mui_components/SpectraGridContainer");
const SpectraItem_1 = require("../../../mui_components/SpectraItem");
const SpectraTypography_1 = require("../../../mui_components/SpectraTypography");
const SpectraBox_1 = require("../../../mui_components/SpectraBox");
const Constants_1 = require("../../../../utils/Constants");
const services_1 = require("../../../../store/auth/services");
const react_toastify_1 = require("react-toastify");
const utils_1 = require("../../../../utils");
const VerifyUserWeb = ({ route, navigation }) => {
    const [verificationApi] = (0, services_1.useVerifyUserMutation)();
    const [isInitiliazing, setIsInitiliazing] = (0, react_1.useState)(false);
    const verifyUser = () => {
        verificationApi(route.params.token)
            .unwrap()
            .then((response) => {
            setIsInitiliazing(false);
            react_toastify_1.toast.success((0, utils_1.getLimitedText)(response?.details.length, 80));
            navigation.navigate('LoginScreenWeb');
        })
            .catch((error) => {
            react_toastify_1.toast.error((0, utils_1.getLimitedText)(error.detail.length, 80));
        });
    };
    (0, react_1.useEffect)(() => {
        verifyUser();
    }, []);
    return (react_1.default.createElement(SpectraBox_1.SpectraBox, { styles: {
            p: 0,
            paddingTop: '18%',
            borderColor: Constants_1.Colors.TRANSPARENT,
            bgcolor: Constants_1.Colors.TRANSPARENT,
        } },
        react_1.default.createElement(SpectraGridContainer_1.SpectraGridContainer, null,
            react_1.default.createElement(SpectraItem_1.SpectraItem, null,
                react_1.default.createElement(SpectraTypography_1.SpectraTypography, { text: "Verifying Your Account..." })))));
};
exports.default = VerifyUserWeb;
