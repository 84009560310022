"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable react-native/no-inline-styles */
const react_1 = __importStar(require("react"));
const react_map_gl_1 = require("react-map-gl");
const react_redux_1 = require("react-redux");
const azimuthCircleImage = require('../../../../assets/appicons/sectorIcons/azimuth_compass.svg');
const AzimuthMarker = ({ longitude, latitude, image }, ref) => {
    const [rot, setRot] = (0, react_1.useState)(0);
    const selectedSectorId = (0, react_redux_1.useSelector)((state) => state.azimuthValues.currentSector);
    const azimuth1 = (0, react_redux_1.useSelector)((state) => state.azimuthValues.azimuth1);
    const azimuth2 = (0, react_redux_1.useSelector)((state) => state.azimuthValues.azimuth2);
    const azimuth3 = (0, react_redux_1.useSelector)((state) => state.azimuthValues.azimuth3);
    const azimuth4 = (0, react_redux_1.useSelector)((state) => state.azimuthValues.azimuth4);
    const azimuth5 = (0, react_redux_1.useSelector)((state) => state.azimuthValues.azimuth5);
    const azimuth6 = (0, react_redux_1.useSelector)((state) => state.azimuthValues.azimuth6);
    (0, react_1.useEffect)(() => {
        switch (selectedSectorId) {
            case 0:
                setRot(azimuth1);
                break;
            case 1:
                setRot(azimuth1);
                break;
            case 2:
                setRot(azimuth2);
                break;
            case 3:
                setRot(azimuth3);
                break;
            case 4:
                setRot(azimuth4);
                break;
            case 5:
                setRot(azimuth5);
                break;
            case 6:
                setRot(azimuth6);
                break;
            default:
                break;
        }
    }, [
        selectedSectorId,
        azimuth1,
        azimuth2,
        azimuth3,
        azimuth4,
        azimuth5,
        azimuth6,
    ]);
    (0, react_1.useImperativeHandle)(ref, () => ({
        incrementAzimuth: () => {
            setRot((prevRot) => prevRot + 1);
        },
        decrementAzimuth: () => {
            setRot((prevRot) => prevRot - 1);
        },
        resetAzimuth: () => {
            setRot(0);
        },
    }));
    return (react_1.default.createElement(react_map_gl_1.Marker, { longitude: longitude, latitude: latitude, anchor: "bottom", draggable: false },
        react_1.default.createElement("div", { style: {
                position: 'absolute',
                width: '600px',
                height: '600px',
                top: '-283px',
                left: '-240.5px',
            } },
            react_1.default.createElement("img", { src: azimuthCircleImage, style: {
                    position: 'absolute',
                    alignSelf: 'center',
                    width: '481px',
                    height: '481px',
                    objectFit: 'contain',
                } }),
            react_1.default.createElement("img", { src: image, style: {
                    alignSelf: 'center',
                    position: 'absolute',
                    marginTop: '162px',
                    marginLeft: '107.5px',
                    width: '265px',
                    height: '177px',
                    transformOrigin: 'center',
                    transform: `rotate(${rot - 45}deg)`,
                    objectFit: 'contain',
                } }))));
};
exports.default = (0, react_1.forwardRef)(AzimuthMarker);
