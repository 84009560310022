"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Font = void 0;
//@ts-nocheck
const react_native_1 = require("react-native");
const roboto = 'Roboto-Regular';
const lightBold = '300';
const mediumLightBold = '400';
const mediumBold = '500';
const extraBold = '600';
const superSmall = react_native_1.Platform.OS === 'web' ? '12px' : 12;
const extraSmall = react_native_1.Platform.OS === 'web' ? '14px' : 14;
const small = react_native_1.Platform.OS === 'web' ? '15px' : 15;
const medium = react_native_1.Platform.OS === 'web' ? '16px' : 16;
const large = react_native_1.Platform.OS === 'web' ? '18px' : 18;
const extraLarge = react_native_1.Platform.OS === 'web' ? '24px' : 24;
exports.Font = {
    roboto,
    medium,
    mediumBold,
    small,
    extraLarge,
    extraSmall,
    lightBold,
    mediumLightBold,
    large,
    superSmall,
    extraBold,
};
