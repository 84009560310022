"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const styles_1 = require("@mui/styles");
const Constants_1 = require("../../../utils/Constants");
exports.useStyles = (0, styles_1.makeStyles)(() => ({
    defaultIconColor: {
        color: Constants_1.Colors.SILVER_CHALICE
    },
    signUpIconBtns: {
        backgroundColor: Constants_1.Colors.WHITE,
        borderRadius: 4
    },
    checkboxWrapper: {
        paddingLeft: 20,
        paddingTop: 24,
        "& .MuiGrid-item": {
            paddingLeft: "unset"
        },
        "& .MuiGrid-container": {
            flexDirection: "row",
            flexWrap: "unset",
            justifyContent: "flex-start",
            gap: 10
        },
        "& .MuiCheckbox-root": {
            paddingInline: "unset"
        }
    },
    eyeIconButton: {
        height: 24,
        cursor: "pointer"
    }
}));
