"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const styles_1 = require("@mui/styles");
exports.useStyles = (0, styles_1.makeStyles)((them) => ({
    customTableContainer: {
        paddingInline: 16,
        "& .MuiTableCell-root": {
        // borderBottom: 0
        },
        "& .MuiTableHead-root": {
            "& .MuiTableCell-root": {
                "&:first-child": {
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6
                },
                "&:last-child": {
                    borderTopRightRadius: 6,
                    borderBottomRightRadius: 6
                }
            },
        },
        "& .MuiTableBody-root": {
            "& .MuiTableRow-root": {
                "&:hover": {
                    "& .MuiTableCell-root": {
                        "&:first-child": {
                            borderTopLeftRadius: 6,
                            borderBottomLeftRadius: 6
                        },
                        "&:last-child": {
                            borderTopRightRadius: 6,
                            borderBottomRightRadius: 6
                        }
                    },
                }
            }
        }
    },
}));
