"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapTypeActions = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    mapType: 0, // Outdoor by default
};
const mapTypeSlice = (0, toolkit_1.createSlice)({
    name: 'mapType',
    initialState,
    reducers: {
        setMapType: (state, action) => {
            state.mapType = action.payload;
        },
    },
});
exports.MapTypeActions = mapTypeSlice.actions;
exports.default = mapTypeSlice.reducer;
