"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AzimuthAngleButton = void 0;
//@ts-nocheck
const react_1 = __importStar(require("react"));
const react_native_1 = require("react-native");
const react_native_paper_1 = require("react-native-paper");
const Constants_1 = require("../../../utils/Constants");
const react_redux_1 = require("react-redux");
const actions_1 = require("../../../store/maps/actions");
const AzimuthAngleButton = ({ incrementAngle, decrementAngle, resetAzimuth, close, }) => {
    const azimuth1 = (0, react_redux_1.useSelector)((state) => state.azimuthValues.azimuth1);
    const azimuth2 = (0, react_redux_1.useSelector)((state) => state.azimuthValues.azimuth2);
    const azimuth3 = (0, react_redux_1.useSelector)((state) => state.azimuthValues.azimuth3);
    const azimuth4 = (0, react_redux_1.useSelector)((state) => state.azimuthValues.azimuth4);
    const azimuth5 = (0, react_redux_1.useSelector)((state) => state.azimuthValues.azimuth5);
    const azimuth6 = (0, react_redux_1.useSelector)((state) => state.azimuthValues.azimuth6);
    const [rot, setRot] = react_1.default.useState(0);
    const [azimuthValueState, setAzimuthValueState] = react_1.default.useState(0);
    const mountingType = (0, react_redux_1.useSelector)((state) => state.azimuthValues.mountingType);
    const sectorId = (0, react_redux_1.useSelector)((state) => state.azimuthValues.numberSectors);
    const selectedSectorId = (0, react_redux_1.useSelector)((state) => state.azimuthValues.currentSector);
    (0, react_1.useEffect)(() => {
        switch (selectedSectorId) {
            case 0:
                setRot(azimuth1);
                break;
            case 1:
                setRot(azimuth1);
                break;
            case 2:
                setRot(azimuth2);
                break;
            case 3:
                setRot(azimuth3);
                break;
            case 4:
                setRot(azimuth4);
                break;
            case 5:
                setRot(azimuth5);
                break;
            case 6:
                setRot(azimuth6);
                break;
            default:
                break;
        }
    }, [
        selectedSectorId,
        azimuth1,
        azimuth2,
        azimuth3,
        azimuth4,
        azimuth5,
        azimuth6,
    ]);
    const AzimuthCalculation = (rotation, offset_of_antenna) => {
        if (rotation < 0) {
            rotation += 360;
        }
        if (selectedSectorId === 1) {
            (0, actions_1.addAzimuth1Dispatch)(rotation);
        }
        else if (selectedSectorId === 2) {
            (0, actions_1.addAzimuth2Dispatch)(rotation);
        }
        else if (selectedSectorId === 3) {
            (0, actions_1.addAzimuth3Dispatch)(rotation);
        }
        else if (selectedSectorId === 4) {
            (0, actions_1.addAzimuth4Dispatch)(rotation);
        }
        else if (selectedSectorId === 5) {
            (0, actions_1.addAzimuth5Dispatch)(rotation);
        }
        else if (selectedSectorId === 6) {
            (0, actions_1.addAzimuth6Dispatch)(rotation);
        }
    };
    const incrementRotation = () => {
        setRot((previousRot) => previousRot + 1);
        incrementAngle();
    };
    const decrementRotation = () => {
        setRot((previousRot) => previousRot - 1);
        decrementAngle();
    };
    const resetRotation = () => {
        setRot(0);
        resetAzimuth();
    };
    const submitAzimuth = () => { };
    return (react_1.default.createElement(react_native_1.View, null,
        react_1.default.createElement(react_native_paper_1.IconButton, { style: {
                borderColor: Constants_1.Colors.CARIBBEAN_GREEN,
                borderWidth: '1px',
                alignSelf: 'center',
                backgroundColor: Constants_1.Colors.WHITE,
                fontFamily: 'Roboto,sans-serif',
            }, color: Constants_1.Colors.CARIBBEAN_GREEN, icon: "check", onPress: () => {
                AzimuthCalculation(rot, azimuthValueState);
                close();
            } }, "submit"),
        react_1.default.createElement(react_native_1.View, { style: {
                width: Constants_1.Constants.relative_width(86),
                height: Constants_1.Constants.relative_height(126),
                backgroundColor: Constants_1.Colors.WHITE,
                borderRadius: 5,
                alignContent: 'center',
                justifyContent: 'space-between',
            } },
            react_1.default.createElement(react_native_paper_1.Button, { style: { backgroundColor: Constants_1.Colors.BLUE }, contentStyle: { height: Constants_1.Constants.relative_height(38) }, onPress: incrementRotation, disabled: rot === 360 ? true : false },
                react_1.default.createElement(react_native_paper_1.Text, { style: { color: Constants_1.Colors.WHITE } }, "+")),
            react_1.default.createElement(react_native_paper_1.Text, { style: { textAlign: 'center', fontFamily: 'Roboto,sans-serif' } },
                rot < 0 ? 360 + rot : rot,
                "\u00B0N"),
            react_1.default.createElement(react_native_paper_1.Button, { style: { backgroundColor: Constants_1.Colors.BLUE }, contentStyle: { height: Constants_1.Constants.relative_height(38) }, onPress: decrementRotation },
                react_1.default.createElement(react_native_paper_1.Text, { style: { color: Constants_1.Colors.WHITE } }, "-"))),
        react_1.default.createElement(react_native_paper_1.IconButton, { style: {
                borderColor: Constants_1.Colors.RED,
                borderWidth: '1px',
                alignSelf: 'center',
                backgroundColor: Constants_1.Colors.WHITE,
                fontFamily: 'Roboto,sans-serif',
            }, color: Constants_1.Colors.RED, icon: "reload", onPress: resetRotation }, "reset")));
};
exports.AzimuthAngleButton = AzimuthAngleButton;
