"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const styles_1 = require("@mui/styles");
const Constants_1 = require("../../../utils/Constants");
exports.useStyles = (0, styles_1.makeStyles)((them) => ({
    customShape: {
        minWidth: 148,
        height: 54,
        backgroundColor: "#EDEDF0",
        clipPath: "polygon(0px 0, 81% 0, 100% 100%, 0 100%, 39px 100%, 0 100%)",
        position: "relative",
        borderRadius: "8px 47px 0px 0px",
        borderColor: Constants_1.Colors.LIGHTGREY,
        // borderWidth: 1,
        // borderStyle: "solid",
        "&::before": {
            content: "",
            position: "absolute",
            top: 0,
            right: 0,
            width: 10,
            height: "100%",
            backgroundColor: "#EDEDF0",
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0)"
        }
    },
    tabWrapper: {
        marginRight: -6,
        "& .active": {
            backgroundColor: Constants_1.Colors.WHITE,
            borderColor: Constants_1.Colors.TRANSPARENT,
            transition: "300ms"
        }
    },
    textLabels: {
        position: "absolute",
        width: "100%",
        height: "100%"
    }
}));
