"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkForNumbers = exports.removeSpecialCharacters = exports.encodeQueryData = exports.getExtension = exports.isWeb = void 0;
const react_native_1 = require("react-native");
function isWeb() {
    return react_native_1.Platform.OS === 'web';
}
exports.isWeb = isWeb;
function getExtension(filepath) {
    return filepath.split('?')[0].split('#')[0].split('.').pop();
}
exports.getExtension = getExtension;
function encodeQueryData(data) {
    const ret = [];
    for (let d in data)
        ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return ret.join('&');
}
exports.encodeQueryData = encodeQueryData;
function removeSpecialCharacters(value) {
    return value.replace(/[^a-zA-Z0-9\s]/g, '');
}
exports.removeSpecialCharacters = removeSpecialCharacters;
function checkForNumbers(value) {
    return value.replace(/[^0-9]/g, '');
}
exports.checkForNumbers = checkForNumbers;
