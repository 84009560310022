"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AzimuthActions = exports.addAzimuthValues = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    mountingType: '',
    numberSectors: 0,
    currentSector: 0,
    azimuthValue: [45, 225, 0, 0, 0, 0],
    azimuth1: 0,
    azimuth2: 0,
    azimuth3: 0,
    azimuth4: 0,
    azimuth5: 0,
    azimuth6: 0,
};
exports.addAzimuthValues = (0, toolkit_1.createSlice)({
    name: 'azimuthValues',
    initialState,
    reducers: {
        addMountingType(state, action) {
            state.mountingType = action.payload;
        },
        addNumberSectors(state, action) {
            state.numberSectors = action.payload;
        },
        addCurrentSector(state, action) {
            state.currentSector = action.payload;
        },
        addAzimuthValue(state, action) {
            state.azimuthValue = action.payload;
        },
        addAzimuth1(state, action) {
            state.azimuth1 = action.payload;
        },
        addAzimuth2(state, action) {
            state.azimuth2 = action.payload;
        },
        addAzimuth3(state, action) {
            state.azimuth3 = action.payload;
        },
        addAzimuth4(state, action) {
            state.azimuth4 = action.payload;
        },
        addAzimuth5(state, action) {
            state.azimuth5 = action.payload;
        },
        addAzimuth6(state, action) {
            state.azimuth6 = action.payload;
        },
    },
});
// Action creators are generated for each case reducer function
exports.AzimuthActions = exports.addAzimuthValues.actions;
exports.default = exports.addAzimuthValues.reducer;
