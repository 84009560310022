"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setMacAddress = exports.setItemNumber = exports.setproductNumber = exports.setLoader = exports.setBarcodeValue = exports.barcodeSlice = void 0;
//@ts-nocheck
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    barcodeValue: "",
    loader: false,
    productNumber: "",
    itemNumber: "",
    macAddress: ""
};
exports.barcodeSlice = (0, toolkit_1.createSlice)({
    name: 'barcodeReader',
    initialState,
    reducers: {
        setBarcodeValue: (state, action) => {
            state.barcodeValue = action.payload;
        },
        setLoader: (state, action) => {
            state.loader = action.payload;
        },
        setproductNumber: (state, action) => {
            state.productNumber = action.payload;
        },
        setItemNumber: (state, action) => {
            state.setItemNumber = action.payload;
        },
        setMacAddress: (state, action) => {
            state.macAddress = action.payload;
        },
    },
});
// Action creators are generated for each case reducer function
_a = exports.barcodeSlice.actions, exports.setBarcodeValue = _a.setBarcodeValue, exports.setLoader = _a.setLoader, exports.setproductNumber = _a.setproductNumber, exports.setItemNumber = _a.setItemNumber, exports.setMacAddress = _a.setMacAddress;
exports.default = exports.barcodeSlice.reducer;
