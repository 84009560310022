"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.vendorValidationSchema = void 0;
const yup = __importStar(require("yup"));
exports.vendorValidationSchema = yup.object({
    vendor_name: yup
        .string()
        .max(128, 'Vendor name should not be more than 64 characters')
        .required('Vendor name is required'),
    model_name: yup
        .string()
        .max(128, 'Model name should not be more than 64 characters')
        .required('Model name is required'),
    antenna_pattern: yup.string(),
    fccId: yup
        .string()
        .max(19, 'FCC ID should not be more than 19 characters')
        .required('FCC ID is required'),
    antennaBeamwidth: yup
        .number()
        .min(0, 'Antenna beamwidth should not be less than 0')
        .max(360, 'Antenna beamwidth should not be more than 360')
        .required('Antenna beamwidth is required'),
    antennaModel: yup
        .string()
        .max(128, 'Antenna model should not be more than 128 characters')
        .required('Antenna model is required'),
    softwareVersion: yup
        .string()
        .max(64, 'Software version should not be more than 64 characters')
        .required('Software version is required'),
    hardwareVersion: yup
        .string()
        .max(64, 'Hardware version should not be more than 64 characters')
        .required('Hardware version is required'),
    firmwareVersion: yup
        .string()
        .max(64, 'Firmware version should not be more than 64 characters')
        .required('Firmware version is required'),
    antennaGain: yup
        .number()
        .min(-127, 'Antenna gain should not be less than -127')
        .max(128, 'Antenna gain should not be more than 128')
        .required('Antenna gain is required'),
    vendor_type: yup.string().required('Vendor type is required'),
    deployment_type: yup.string().required('Deployment type is required'),
    measurementCapabilities: yup
        .string()
        .required('Measurement capabilities type is required'),
    eirpCapability: yup
        .number()
        .min(-127, 'EIRP capability should not be less than -127')
        .max(47, 'EIRP capability should not be more than 47')
        .required('EIRP capability type is required'),
    technology_name: yup.string().required('Technology name is required'),
    radioTechnology: yup.string().required('Radio technology is required'),
    category: yup.string().required('Category is required'),
    no_of_transmitters: yup.string().required('No. of transmitters is required'),
    sector_default_value: yup.number().when('vendor_type', {
        is: 'directional',
        then: (schema) => schema
            .min(1, 'No. of sectors should not be less than 1')
            .max(6, 'No. of sectors should not be more than 6'),
        otherwise: (schema) => schema.min(0),
    }),
});
