"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setAssetID = exports.setSubmit = exports.setMiniMap = exports.setlng = exports.setlat = exports.HaatReducerSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    latLng: {
        lat: 0,
        lng: 0,
    },
    miniMap: false,
    submit: false,
    assetID: null,
};
exports.HaatReducerSlice = (0, toolkit_1.createSlice)({
    name: 'Haat',
    initialState,
    reducers: {
        setlat: (state, action) => {
            state.latLng.lat = action.payload;
        },
        setlng: (state, action) => {
            state.latLng.lng = action.payload;
        },
        setMiniMap: (state, action) => {
            state.miniMap = action.payload;
        },
        setSubmit: (state, action) => {
            state.submit = action.payload;
        },
        setAssetID: (state, action) => {
            state.assetID = action.payload;
        },
    },
});
// Action creators are generated for each case reducer function
_a = exports.HaatReducerSlice.actions, exports.setlat = _a.setlat, exports.setlng = _a.setlng, exports.setMiniMap = _a.setMiniMap, exports.setSubmit = _a.setSubmit, exports.setAssetID = _a.setAssetID;
exports.default = exports.HaatReducerSlice.reducer;
