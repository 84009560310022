"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResizeHeightWidth = void 0;
const react_1 = require("react");
const ResizeHeightWidth = () => {
    const [size, setSize] = (0, react_1.useState)([0, 0]);
    (0, react_1.useLayoutEffect)(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};
exports.ResizeHeightWidth = ResizeHeightWidth;
