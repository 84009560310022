"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAdmin = exports.MAPBOX_TOKEN = exports.APP_URL = void 0;
// import Config from "react-native-config";
//const env: { APP_URL: string; MAPBOX_TOKEN: string } = JSON.parse(
//  JSON.stringify(process.env.parsed),
//);
exports.APP_URL = "https://dev-gateway-api.c3spectra.cloud/";
exports.MAPBOX_TOKEN = 'sk.eyJ1Ijoibml0YW5rYzMiLCJhIjoiY2xrbDVtMXN5MDJoZDNkcGpqYmowcGpkNCJ9.zr2onex4cHPN29qMaygzBg';
exports.isAdmin = true;
