"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const styles_1 = require("@mui/styles");
const Constants_1 = require("../../../utils/Constants");
exports.useStyles = (0, styles_1.makeStyles)(() => ({
    customInputeField: {
        "& .css-1xio6l4-MuiInputBase-root-MuiOutlinedInput-root": {
            borderColor: `${Constants_1.Colors.DEFAULT_BORDER} !important`,
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: `${Constants_1.Colors.DEFAULT_BORDER} !important`,
                borderWidth: "1px !important"
            },
            "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${Constants_1.Colors.DEFAULT_BORDER} !important`,
                }
            },
        },
        "& .css-1akq9fu-MuiInputBase-root-MuiOutlinedInput-root": {
            borderColor: `${Constants_1.Colors.DEFAULT_BORDER} !important`,
            "&:hover": {
                "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: `${Constants_1.Colors.DEFAULT_BORDER} !important`,
                }
            },
        },
        "& input:-internal-autofill-selected": {
            WebkitBoxShadow: '0 0 0 1000px white inset',
            backgroundColor: 'inherit !important'
        },
        "& .MuiInputAdornment-root, .MuiInputAdornment-positionStart": {
            marginLeft: 14
        },
        "& .MuiInputBase-multiline": {
            paddingLeft: 14
        }
    },
    label: {
        fontSize: 12,
        fontWeight: '500',
        color: Constants_1.Colors.DUSTY_GRAY,
        fontFamily: 'Roboto,sans-serif',
        marginBottom: 6
    },
    desableLable: {
        fontSize: 12,
        fontWeight: '500',
        fontFamily: 'Roboto,sans-serif',
        marginBottom: 6,
        // color: Colors.BLACK,
        color: Constants_1.Colors.DUSTY_GRAY
    }
}));
