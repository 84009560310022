"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPreCalculatedAngle = exports.setPreviousCalculatedAzimuth = exports.azimuthCalculationSlice = void 0;
//@ts-nocheck
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    value: 0,
    previousAngle: 0,
};
exports.azimuthCalculationSlice = (0, toolkit_1.createSlice)({
    name: 'azimuthCalc',
    initialState,
    reducers: {
        setPreviousCalculatedAzimuth: (state, action) => {
            state.value = action.payload;
        },
        setPreCalculatedAngle: (state, action) => {
            state.previousAngle = action.payload;
        },
    },
});
// Action creators are generated for each case reducer function
_a = exports.azimuthCalculationSlice.actions, exports.setPreviousCalculatedAzimuth = _a.setPreviousCalculatedAzimuth, exports.setPreCalculatedAngle = _a.setPreCalculatedAngle;
exports.default = exports.azimuthCalculationSlice.reducer;
