"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackScreen = exports.analyticsApp = exports.firebaseConfig = void 0;
//@ts-nocheck
const firebase = __importStar(require("firebase/app"));
const analytics_1 = require("firebase/analytics");
exports.firebaseConfig = {
    apiKey: 'AIzaSyDHj86a3RUof4dfhxy-PI-60Vvf2oh10dY',
    authDomain: 'spectra-xpro-dev.firebaseapp.com',
    databaseURL: 'https://spectra-xpro-dev-default-rtdb.firebaseio.com',
    projectId: 'spectra-xpro-dev',
    storageBucket: 'spectra-xpro-dev.appspot.com',
    messagingSenderId: '687088087529',
    appId: '1:687088087529:web:16f5fd4a618b87d8bf9b78',
    measurementId: 'G-Y8X3DBVSTZ',
};
const app = firebase.initializeApp(exports.firebaseConfig);
exports.analyticsApp = (0, analytics_1.getAnalytics)(app);
const trackScreen = (current, previous) => {
    (0, analytics_1.logEvent)(exports.analyticsApp, { screen_name: current, screen_class: previous });
};
exports.trackScreen = trackScreen;
