"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStyles = void 0;
const styles_1 = require("@mui/styles");
exports.useStyles = (0, styles_1.makeStyles)({
    clearIcon: {
        height: 18,
        width: 18
    }
});
